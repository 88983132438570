
import { defineComponent, ref, inject } from "vue";
import axios from "@/api/axios";
import api from "@/api/axios";
import qs from "qs";
import Modal from "@/components/UI/Modal";
import Toast from "@/components/UI/Toast";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  name: "WechatRed",
  setup(){
    const route = useRoute();
    let code = route.query.code as string
    let state = route.query.state as string

    const href = window.location.href
    const codeReg = /code=(.*?)&/
    const stateReg = /state=(.*)/

    if (href.match(codeReg)) {
        code = RegExp.$1
    }
    if (href.match(stateReg)) {
        state = RegExp.$1
    }
    const router = useRouter();
    const arr = [1,2,3,4,5,6,7,8];
    let i = 0;//转到哪个位置
    let count= 0;//转圈初始值
    const totalCount = 3;//转动的总圈数
    let speed = 300;//转圈速度，越大越慢
    const minSpeed = 300;
    let timer: any = null;
    let isClick = false;
    let index = 3;//指定转到哪个奖品
    const text = ref("100元代金券");//奖品
    const activeNum = ref(0);
    const imgUrl = ref("https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/cash_10020210113.png");
    const imgStyle = ref({
      width: "1rem",
      left: "1.46rem",
      top: "1.5rem",
    });
    const imgUrlArr = [
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/cash_10020210113.png",
        text: "100元代金券"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png",
        text: "100元红包"
      },
      {
        img:  "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
        text: "168积分"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png",
        text: "2.88元红包"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/cash_20020210113.png",
        text: "200元代金券"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png",
        text: "66.88元红包"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
        text: "888积分"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png",
        text: "10.00元红包"
      },
    ]
    const imgArr = [
        {
            img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/cash_10020210113.png",
            width: "1rem",
            left: "1.46rem",
            top: "1.5rem",
        },
        {
            img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/cash_20020210113.png",
            width: "1rem",
            left: "1.46rem",
            top: "1.5rem",
        },
        {
            img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
            width: "0.7rem",
            left: "1.63rem",
            top: "1.4rem",
        },
        {
            img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png",
            width: "0.7rem",
            left: "1.63rem",
            top: "1.4rem",
        }
    ]
    const dialogShow = ref(false);

    const redId = [
      {
          id: '44',
          index: 6
      },
      {
          id: '43',
          index: 3
      },
      {
          id: '42',
          index: 7
      },
      {
          id: '41',
          index: 8
      },
      {
          id: '40',
          index: 4
      }
    ]
    const sendType = ref()
    const redPrice = ref()

    function roll(){
        //速度衰减
        speed -= 50;
        if(speed<=10){
            speed=10;
        }
        //每次调用都去掉全部active类名
        activeNum.value = 10;
        i++;
        //计算转圈次数
        if(i >= 8){
            i = 0;
            count++;
        }
        activeNum.value = i;
        //满足转圈数和指定位置就停止
        if (count >= totalCount && (i + 1) == index){
            clearTimeout(timer);
            speed = minSpeed;
            text.value = imgUrlArr[i].text;
            imgUrl.value = imgUrlArr[i].img;
            isClick = true;
            for (let j = 0; j < imgArr.length; j++) {
                if (imgArr[j].img == imgUrl.value) {
                  imgStyle.value = {
                    width: imgArr[j].width,
                    left: imgArr[j].left,
                    top: imgArr[j].top,
                  }
                }
            }
            setTimeout(function () {
              if(sendType.value == '0') {
                dialogShow.value = true;
              }else {
                Toast({
                  title: '恭喜抽中'+redPrice.value+'元红包，但是红包发送异常，请截图联系客服领取!',
                  type: "error",
                  duration: 6000
                });
              }
            }, 1200)
        }else{
            timer = setTimeout(roll,speed);//不满足条件时调用定时器
            //最后一圈减速
            if(count >= totalCount-1 || speed <= 50){
                speed += 100;
            }
        }
    }


    function handleDialog(){
      dialogShow.value = false;
    }
    // 防抖
    function debounce(fn: any, wait: any) {
      let timeout: any = null;
      return function() {
        if(timeout !== null)
          clearTimeout(timeout);
        timeout = setTimeout(fn, wait);
      }
    }
    function handleLuckDraw() {
      //通过防抖确保用户多次点击为最多1S一次
      debounce(api.post('/M/Event/doOrderingRedPacket', qs.stringify({
            code,
            state,
            idStr: '12'
        })).then((res) => {
            const data = res.data
            if(data.msg == '请先登录再执行操作') {
              Toast({
                title: '请先登录',
                type: "error",
              });
              router.push({
                path: "/login"
              });
              return false
            }
            if (data.success) {
              if (data.msg == 'noFollow') {
                Toast({
                  title: '您还未关注BNCC微信公众号，请先关注再抽奖！',
                  type: "error",
                  duration: 2000
                });
                return false
              }
              let idx = 1
              redId.forEach(item => {
                  if (item.id == data.data.id) {
                      idx = item.index
                  }
              })
              index = idx;
              sendType.value = data.type
              roll();
              redPrice.value = data.link
              // if (data.type == '1') {
              //   Toast({
              //     title: '抽奖成功，但是红包发送异常!',
              //     type: "error",
              //   });
              // }
            } else {
              Toast({
                title: res.data.msg,
                type: "error",
                duration: 2000
              });
            }
        }).catch(e => {
            Toast({
              title: '未知错误，请联系客服',
              type: "success"
          })
        }),1000)
    }



    return {
      text,
      imgUrl,
      handleLuckDraw,
      imgStyle,
      activeNum,
      dialogShow,
      handleDialog,
      redPrice
    }
  }
});
